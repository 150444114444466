html, body {

  width: 100em;
  height: 100em;
  max-width: 100em;
  color: white;

}

div{

  height: 100em;
  width: 100em;
  position: relative;

}

img {

  width: 20em;
  padding-bottom: 0%;
}

.insta {
  margin-top: 15px;
  width: 16px;
}

.box {
  position: relative;
  width: 27em;
  height: 19em;
  top: -20em;
  left: 35em;
  background-color: #1a1a1a;
  font-size: 100%;
  font-family: "Lucida Console", "Courier New", monospace;
  box-shadow: 1px 1px 5px 5px rgb(29, 29, 29);
  padding-bottom: 0%;
}

.p1{

  font-size: 100%;
  font-family: "Lucida Console", "Courier New", monospace;

}

p2 {
  position: relative;
  left: 51em;
  top: -20em;
  font-size: 100%;
  font-family: "Lucida Console", "Courier New", monospace;
}

a{

  color: white;
  text-decoration: none;
  font-size: 100%;
  font-family: "Lucida Console", "Courier New", monospace;

}

p{

  font-size: 100%;
  font-family: "Lucida Console", "Courier New", monospace;

}

p3{

  position: relative;
  left: -4.3em;
  color: white;
  text-decoration: none;
  font-size: 100%;
  font-family: "Lucida Console", "Courier New", monospace;

}


.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
